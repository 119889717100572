var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanyFleetView py-4",
      attrs: { "data-test-id": "company_fleet-view" },
    },
    [
      _c("h2", { staticClass: "emobg-font-weight-semibold mb-2" }, [
        _vm._v(" Dedicated fleet "),
      ]),
      _c("MuiAlgoliaList", {
        attrs: {
          index: _vm.ALGOLIA_INDEXES.vehicles,
          "table-config": _vm.contentCells,
          filters: _vm.getVehiclesFilter,
          facets: _vm.facets,
          "empty-message": "No dedicated fleet found",
          "data-test-id": "vehicles-table",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }