<script>
import { mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { getNoResultsLabel } from '@/utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { contentCells, facets } from './config/contentCells';
import { COMPANY_SCOPES } from '../../store/CompanyModule';

export default {
  name: 'CompanyFleetView',
  components: {
    MuiAlgoliaList,
  },
  methods: { getNoResultsLabel },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
    getVehiclesFilter() {
      return `dedicated_cs_operator_uuid:${this.company.dedicatedFleetCsOperatorUuid}`;
    },
  },
  async created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells;
    this.facets = facets;
  },
};
</script>
<template>
  <div
    class="CompanyFleetView py-4"
    data-test-id="company_fleet-view"
  >
    <h2 class="emobg-font-weight-semibold mb-2">
      Dedicated fleet
    </h2>
    <MuiAlgoliaList
      :index="ALGOLIA_INDEXES.vehicles"
      :table-config="contentCells"
      :filters="getVehiclesFilter"
      :facets="facets"
      empty-message="No dedicated fleet found"
      data-test-id="vehicles-table"
    />
  </div>
</template>
