var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ui-badge", {
    staticClass: "BadgeComponent",
    attrs: { color: _vm.color, text: _vm.text, square: _vm.square },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }